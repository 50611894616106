/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 58.8.14-v202407032053
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/hybrid/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqUsageApiClass {
 
   constructor() {}

  /**
   * @summary Get the list of available types that can be used as filter values for the reporting endpoint
   */
  public getTypes(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/usage/types`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UsageTypesV1>;
  }

  /**
   * @summary Get usage records for the specified time range. Results will be sorted by date if aggregating by day, otherwise by amount descending and limited to the first 500 records
   * @param {string} startTime - Start time for the time frame (YYYY-MM-DDThh:mm:ss±hh:mm)
   * @param {string} endTime - End time for the time frame (YYYY-MM-DDThh:mm:ss±hh:mm)
   * @param {Array<string>} [type] - Filter by type of usage (e.g. Analysis, Screenshot)
   * @param {string} [source] - Filter by any part of the origin label or origin url fields
   * @param {Array<string>} [identityId] - Filter by the UUID of the user who initiated the request
   * @param {Array<string>} [aggregateBy] - Aggregate the usage records by one or more fields. Cannot aggregate by both Day and Month at the same time.
   */
  public getUsage(
    {
      startTime,
      endTime,
      type,
      source,
      identityId,
      aggregateBy
    } : {
      startTime: string,
      endTime: string,
      type?: Array<string>,
      source?: string,
      identityId?: Array<string>,
      aggregateBy?: AggregateByEnum[]
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(startTime)) {
      throw new Error("'startTime' parameter required");
    }

    if (isNil(endTime)) {
      throw new Error("'endTime' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/usage`,

      params: omitBy({
        ['startTime']: startTime,
        ['endTime']: endTime,
        ['type']: type,
        ['source']: source,
        ['identityId']: identityId,
        ['aggregateBy']: aggregateBy
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.UsageOutputListV1>;
  }

}

export enum AggregateByEnum {
    User = 'User' as any,
    Type = 'Type' as any,
    Source = 'Source' as any,
    Day = 'Day' as any,
    Month = 'Month' as any
}

export const sqUsageApi = new sqUsageApiClass();
